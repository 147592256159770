import React from "react"
import { navigate } from "gatsby"

export default class Index extends React.Component {
  componentDidMount() {
    navigate("hyvat-ystavat/")
  }

  render() {
    return null
  }
}
